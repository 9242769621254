import * as React from 'react';
import { Button, Snackbar } from '@mui/material'
import { useState } from 'react'

import CopyAllIcon from '@mui/icons-material/CopyAll';

type CopyToClipboardButtonProps = {

    data: string;
}

const CopyToClipboardButton = (props: CopyToClipboardButtonProps) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(props.data);
    }

    return (
        <>
            <Button onClick={handleClick}><CopyAllIcon></CopyAllIcon></Button>
            <Snackbar
                open={open}
                anchorOrigin={{ vertical:'top', horizontal: 'center' }}
                onClose={() => setOpen(false)}
                autoHideDuration={2000}
                message="Tilinumero kopioitu leikepöydälle"
                ContentProps={{
                    sx: {
                        display: 'block',
                        textAlign: 'center'
                    }
                }}
            />
        </>
    )
}

export default CopyToClipboardButton