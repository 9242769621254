import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { MessageContext, LoadingContext, UserContext, GlobalConfigContext } from '../App';
import { GiftDataDto, UserDataDto } from '../services/WeddingPageAPIClient';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';
import { AxiosError } from 'axios';
import StyledPaper from '../component/StyledPaper';
import { Button, Container, Grid, Paper, Typography } from '@mui/material';
import StyledTypography from '../component/StyledTypography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './GiftsPage.css';
import CopyToClipboardButton from '../component/CopyToClipboardButton';
import { MessageType } from '../component/GlobalMessageModal';

const GiftsPage = () => {

    const contextMessage = useContext(MessageContext);
    const contextUser = useContext(UserContext);
    const context = useContext(LoadingContext);
    const contextGlobalConfigs = useContext(GlobalConfigContext);
    const contextLoading = useContext(LoadingContext);

    const [formData, setFormData] = useState<GiftDataDto[]>([]);
    const [giftToReserve, setGiftToReserve] = useState<GiftDataDto>();

    const client = WeddingPageAPIClient.GetInstance();

    const refreshGifts = () => {

        context.setLoading({isLoading: true, message: 'Ladataan'});
        client.getGifts().then((giftData: GiftDataDto[]) => {

            setFormData(giftData);
            setGiftToReserve(undefined);

        }).catch((error: AxiosError) => {

            contextMessage.setGlobalMessage({
                title: undefined,
                message: 'Virhe haettaessa lahjalistan tietoja!',
                details: JSON.stringify(error.response || error.message, null, 2),
                type: MessageType.ERROR
            })
        }).finally(() => {

            context.setLoading({isLoading: false});
        });
    }


    const releaseGift = async (gift: GiftDataDto) => {

        setGiftToReserve(undefined);
        contextLoading.setLoading({isLoading: true, message: 'Tallennetaan'});

        await client.releaseGift(gift.id!)

            .then((res: GiftDataDto) => {
                /*
                contextMessage.setGlobalMessage({
                    title: 'Lahjan varauksen poisto onnistui!',
                    message: `Vapautit onnistuneesti lahjan ${gift.name} varauksen`,
                    details: JSON.stringify(res),
                    type: MessageType.INFO
                });
 */
                refreshGifts();
            })

            .catch((error: AxiosError) => {

                contextMessage.setGlobalMessage({
                    title: undefined,
                    message: 'Virhe poistettaessa lahjan varaustietoja!',
                    details: JSON.stringify(error.response || error.message, null, 2),
                    type: MessageType.ERROR
                })
            })

            .finally(() => contextLoading.setLoading({isLoading: false}));
    }

    const reserveGift = async (gift: GiftDataDto) => {

        setGiftToReserve(undefined);
        contextLoading.setLoading({isLoading: true, message: 'Tallennetaan'});

        await client.reserveGift(gift.id!)

            .then((res: GiftDataDto) => {
                /*
                contextMessage.setGlobalMessage({
                    title: 'Lahjan varauksen tallennus onnistui!',
                    message: `Varasit onnistuneesti lahjan ${gift.name}`,
                    details: JSON.stringify(res),
                    type: MessageType.INFO
                });
 */
                refreshGifts();
            })

            .catch((error: AxiosError) => {

                contextMessage.setGlobalMessage({
                    title: undefined,
                    message: 'Virhe tallennettaessa lahjan varaustietoja!',
                    details: JSON.stringify(error.response || error.message, null, 2),
                    type: MessageType.ERROR
                })
            })

            .finally(() => contextLoading.setLoading({isLoading: false}));
    }

    useEffect(refreshGifts, []);

    return (
        <Container style={{minHeight: '50vh', paddingBottom: '50px'}}>
            <StyledTypography variant='h2'>
                Muistaminen
            </StyledTypography>
            <Typography>
                Mikäli haluatte muistaa meitä hääpäivänämme, toiveenamme on, että tavaroiden sijaan kartuttaisitte yhteistä häämatkatiliämme.
            </Typography>
            <br/>
            <div style={{alignContent: 'left'}}>
                Tilinumero:
                <br/>
                <br/>
                <span style={{
                    backgroundColor: 'rgb(255 249 239)',
                    margin: '8px',
                    padding: '8px',
                    borderRadius: '5px 5px 5px',
                    border: '1px solid rgb(173 157 84)',
                    paddingLeft: '10px',
                    paddingRight: '0px',
                }}>
                    <code>{contextGlobalConfigs?.globalConfigs?.gifts_IBAN} </code>
                    <CopyToClipboardButton data={contextGlobalConfigs?.globalConfigs?.gifts_IBAN || '-'} />
                </span>
                <br/>
                <br/>
                Saaja:
                <br/>
                <code>{contextGlobalConfigs?.globalConfigs?.gifts_ReceiverName1}</code>
                <br/>
                tai
                <br/>
                <code>{contextGlobalConfigs?.globalConfigs?.gifts_ReceiverName2}</code>
                <br/>
                <br/>
            </div>
            <StyledTypography variant='h3'>
                Lahjalista
            </StyledTypography>
            <Grid
                container
                spacing={2}
            >
                {formData.map(_ =>
                    GiftContainer(_, contextUser?.userData, giftToReserve?.id == _.id, setGiftToReserve, reserveGift, releaseGift)
                )}
            </Grid>
        </Container>);
};

const GiftContainer = (
    gift: GiftDataDto,
    user: UserDataDto|undefined,
    isReserving: boolean,
    setReserving: Dispatch<SetStateAction<GiftDataDto | undefined>>,
    reserveGift: (gift: GiftDataDto) => void,
    releaseGift: (gift: GiftDataDto) => void,
) => {

    const isReservedSelf = !!gift.isReservedSelf;
    const isReservedOther =  !!gift.isReservedOther
    const isReserved = isReservedSelf || isReservedOther;
    const textColorCode = isReservedOther ? '#d3d3d3' : 'inherit';
    /*
    function reserveButtonClickHandler(gift: GiftDataDto) {


    }
 */
    return (
        <Grid className="fade-in-image"
            item
            key={gift.id}
            xs={12}
            sm={6}
            md={4}>
            <Paper>
                <Typography
                    variant='h5'
                    style={{
                        color: textColorCode
                    }}
                >
                    {gift.name}
                </Typography>
                <img
                    src={gift.imageLink || '/gifts/no_image.png'}
                    width='40%'
                >
                </img>
                <div
                    style={{
                        color: textColorCode
                    }}>
                    {gift.description}
                </div>
                <br></br>
                {!isReservedSelf && !isReservedOther && <div style={{height: '24px'}}></div>}
                {isReservedSelf
                    ?
                    <div className='fade-in-image-fast' style={{fontWeight: 700, color: '#2e7d32'}}>
                        <CheckCircleOutlineIcon
                            style={{verticalAlign:'middle'}}
                        ></CheckCircleOutlineIcon>
                            Olet varannut tämän lahjan
                    </div>
                    : null}
                {isReservedOther
                    ?
                    <div className='fade-in-image-fast' style={{fontWeight: 700}}>
                        <InfoOutlinedIcon
                            style={{verticalAlign:'middle'}}
                        ></InfoOutlinedIcon>
                            Tämä lahja on jo varattu
                    </div>
                    : null
                }
                {ReserveDialog(gift, setReserving, reserveGift, releaseGift, isReserving, isReservedSelf, isReservedOther)}
                <br></br>
                <br></br>
            </Paper>
        </Grid>
    );
}

function ReserveDialog(
    gift: GiftDataDto,
    setReserving: Dispatch<SetStateAction<GiftDataDto | undefined>>,
    reserveGift: (gift: GiftDataDto) => void,
    releaseGift: (gift: GiftDataDto) => void,
    isReserving: boolean,
    isReservedSelf: boolean,
    isReservedOther: boolean,
) {

    // Default state
    if(!isReserving) {

        return (<div>
            <br></br>
            <Button
                disabled={isReservedOther}
                onClick={() => setReserving(gift)}
                style={{cursor: isReservedOther ? 'not-allowed' : ''}}
                variant='contained'
            >
                {isReservedSelf ? 'Poista varaus' : 'Varaa'}
            </Button>
        </div>);
    }

    // Change of reservation active

    // Reserved to self -> cancel reservation
    if(isReservedSelf) {

        return (
            <div className='fade-in-image-fast'>
            Haluatko perua varauksen?
                <br></br>
                <Button
                    variant='contained'
                    color="error"
                    style={{marginRight: '5px'}}
                    onClick={() => releaseGift(gift)}
                >
                    Kyllä
                </Button>
                <Button
                    variant='outlined'
                    style={{marginLeft: '5px'}}
                    onClick={() => setReserving(undefined)}
                >
                    Ei
                </Button>
            </div>
        );
    }

    // Not reserved -> reserveGift
    return (
        <div className='fade-in-image-fast'>
        Haluatko varata tämän lahjan?
            <br></br>
            <Button
                variant='contained'
                color="success"
                style={{marginRight: '5px'}}
                onClick={() => reserveGift(gift)}
            >
            Kyllä
            </Button>
            <Button
                variant='outlined'
                style={{marginLeft: '5px'}}
                onClick={() => setReserving(undefined)}
            >
            Ei
            </Button>
        </div>);

}

export { GiftsPage };