import * as React from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel, GridCallbackDetails, GridComparatorFn, GridValueFormatterParams } from '@mui/x-data-grid';
import { UserDataExtendedDto } from '../services/WeddingPageAPIClient';
import luxon, { DateTime } from 'luxon';

const dateComparator: GridComparatorFn<DateTime> = (v1: DateTime, v2: DateTime) =>  {

    return (v1?.toSeconds() || 0) - (v2?.toSeconds() || 0);
};

const columns: GridColDef<UserDataExtendedDto>[] = [
    { field: 'fullName', headerName: 'Nimi', width: 130 },
    { field: 'isAttending', headerName: 'Osallistuuko', width: 100,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.isAttending
                ? 'kyllä'
                : params.row.isAttending == false
                    ? 'ei'
                    : '(ei vast.)'
    },
    { field: 'busToWeddingPlace', headerName: 'Kulj. juhl.p.', width: 100,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.busToWeddingPlace
                ? 'kyllä'
                : params.row.busToWeddingPlace == false
                    ? 'ei'
                    : '(ei vast.)'
    },
    { field: 'busToCity', headerName: 'Kulj. kaup.', width: 100,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.busToCity
                ? 'kyllä'
                : params.row.busToCity == false
                    ? 'ei'
                    : '(ei vast.)'
    },
    { field: 'specialDiets', headerName: 'Ruokavalio', width: 140 }
];

const columnsExtended: GridColDef<UserDataExtendedDto>[] = [

    {
        field: 'lastLoginTime', headerName: 'Viimeksi kirj.', width: 110,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.lastLoginTime,
        valueFormatter: (params: GridValueFormatterParams<DateTime>) => params.value?.toFormat('d.M.yy HH:mm'),
        sortComparator: dateComparator
    },
    {
        field: 'loginCount', headerName: 'Kirj. kerrat', width: 100,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.loginCount
    },
    {
        field: 'lastVisitTime', headerName: 'Viimeksi vierailtu', width: 150,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.lastPageVisitTime,
        valueFormatter: (params: GridValueFormatterParams<DateTime>) => params.value?.toFormat('d.M.yy HH:mm'),
        sortComparator: dateComparator
    },
    {
        field: 'visitCount', headerName: 'Vierailut', width: 100,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.pageVisitCount
    },
    { field: 'lastEditedByUser', headerName: 'Muokkaaja', width: 100 },
    {
        field: 'lastEditedTime', headerName: 'Muokattu', width: 110,
        valueGetter: (params: {row: UserDataExtendedDto}) =>
            params.row.lastEditedTime,
        valueFormatter: (params: GridValueFormatterParams<DateTime>) => params.value?.toFormat('d.M.yy HH:mm'),
        sortComparator: dateComparator
    },
    { field: 'groupName', headerName: 'Ryhmä', width: 120 },
];

export type UsersTableProps = {

    users: UserDataExtendedDto[]|null,
    extendedProps: boolean,
    setSelectedUsers: (users: UserDataExtendedDto[]) => void
}

export default function UsersTable(props: UsersTableProps) {

    if(!props.users) {
        return <></>;
    }

    const onRowSelectionModelChange = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails<any>) => {

        const selectedUsers = props.users?.filter(_ => (rowSelectionModel as string[]).indexOf(_.id!) >= 0);
        props.setSelectedUsers(selectedUsers || []);
    }

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={props.users}
                columns={
                    props.extendedProps
                        ? [...columns, ...columnsExtended]
                        : columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                    sorting: {
                        sortModel: [{ field: 'groupName', sort: 'asc' }],
                    }
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onRowSelectionModelChange={onRowSelectionModelChange}
            />
        </div>
    );
}