import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import './App.css';
import MainPage from './pages/MainPage';
import LoginPage from './pages/LoginPage';
import InformationPage from './pages/InformationPage';
import RSVPPage from './pages/RSVPPage';
import WorkInProgressPage from './pages/WorkInProgress';
import GalleryPage from './pages/GalleryPage';
import { AuthService } from './services/AuthService';
import { LogoutPage } from './pages/LogoutPage';
import { isDev } from '.';
import { GiftsPage } from './pages/GiftsPage';
import { GlobalConfigsDto, UserDataDto } from './services/WeddingPageAPIClient';
import FaqPage from './pages/FaqPage';
import AdminPage from './pages/AdminPage';
import { MessageType } from './component/GlobalMessageModal';
import ProfilePage from './pages/ProfilePage';
import RSVPPageContainer from './pages/RSVPPageContainer';

export type GlobalMessage = {

    title?: string,
    message?: string,
    details?: string,
    childContent?: JSX.Element,
    type?: MessageType,
}
export type LoadingState = {

    isLoading: boolean,
    message?: string|undefined
}

const LoadingContext = createContext<{ loading: LoadingState|undefined, setLoading: Dispatch<SetStateAction<LoadingState|undefined>>}>({} as any);
const UserContext = createContext<{userData: UserDataDto|undefined, setUserData: Dispatch<SetStateAction<UserDataDto|undefined>>}>({} as any);
const GlobalConfigContext = createContext<{globalConfigs: GlobalConfigsDto|undefined, setGlobalConfigs: Dispatch<SetStateAction<GlobalConfigsDto|undefined>>}>({} as any);
const MessageContext = createContext<{globalMessage: GlobalMessage|undefined, setGlobalMessage: Dispatch<SetStateAction<GlobalMessage|undefined>>}>({} as any);

export { LoadingContext };
export { UserContext };
export { GlobalConfigContext };
export { MessageContext };



function App() {

    /* console.log('IsDev:', isDev()); */
    const[isAuthenticated, setIsAuthenticated] = useState(() => {

        return AuthService.IsAuthenticated();
    });

    function setAuthenticated(token: string|undefined) {

        if(token){

            AuthService.SetToken(token);
        } else {
            AuthService.DeleteToken();
        }
        setIsAuthenticated(!!token);
    }

    // TODO: loading counter for multiple loading forms
    // TODO: admin redirect to info page for non-admins
    const [loading, setLoading] = useState<LoadingState|undefined>(undefined);
    const [globalMessage, setGlobalMessage] = useState<GlobalMessage|undefined>(undefined);
    const [userData, setUserData] = useState<UserDataDto|undefined>(undefined);
    const [globalConfigs, setGlobalConfigs] = useState<GlobalConfigsDto|undefined>(undefined);

    return (
        <div className="App">
            <LoadingContext.Provider value={{loading, setLoading}}>
                <MessageContext.Provider value={{globalMessage, setGlobalMessage}}>
                    <UserContext.Provider value={{userData, setUserData}}>
                        <GlobalConfigContext.Provider value={{globalConfigs, setGlobalConfigs}}>
                            <BrowserRouter>
                                <Routes>
                                    <Route path="/login" element={!isAuthenticated ? <LoginPage setAuthenticated={setAuthenticated} /> : <Navigate to="/" replace />}/>
                                    <Route path="/logout" element={<LogoutPage logoutTimeSecs={3} setAuthenticated={setAuthenticated}/>}/>
                                    <Route path="/logout0" element={<LogoutPage logoutTimeSecs={0} setAuthenticated={setAuthenticated}/>}/>
                                    <Route path="*" element={isAuthenticated ? <MainPage/> : <Navigate to="/login" replace />}>
                                        <Route path="info" element={<InformationPage/>}/>
                                        <Route path="rsvp" element={<RSVPPageContainer/>}/>
                                        <Route path="gifts" element={<GiftsPage/>}/>
                                        <Route path="gallery" element={<GalleryPage/>}/>
                                        <Route path="faq" element={<FaqPage/>}/>
                                        <Route path="playlist" element={<WorkInProgressPage/>}/>
                                        <Route path="thegame" element={<WorkInProgressPage/>}/>
                                        <Route path="admin" element={<AdminPage/>}/>
                                        <Route path="profile" element={<ProfilePage/>}/>
                                        <Route path="*" element={<Navigate to="/info" replace />} />
                                    </Route>
                                </Routes>
                            </BrowserRouter>
                        </GlobalConfigContext.Provider>
                    </UserContext.Provider>
                </MessageContext.Provider>
            </LoadingContext.Provider>
        </div>
    );
}

export default App;
