import React, { useContext } from 'react';
import StyledTypography from '../component/StyledTypography';
import { Container } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';


import  {GlobalConfigContext } from '../App';
import StyledBodyText from '../component/StyledBodyText';

const GalleryPage = () => {

    const contextGlobalConfigs = useContext(GlobalConfigContext);

    return (
        <Container className="fade-in-image">
            <StyledTypography variant='h2'>
                Galleria
            </StyledTypography>
            <StyledTypography variant='h4'>
                <img
                    style={{
                        height: '40px',
                        verticalAlign:'middle',
                        paddingRight: '3px'
                    }}
                    src='/img/camera_flash.png'
                    alt='gphotos'
                >
                </img>
                <span style={{
                    color: 'currentColor',
                    cursor: 'not-allowed',
                    opacity: 1,
                    textDecoration: 'none'
                }}>
                       Hääkuvat Albumi
                </span>
            </StyledTypography>
            <StyledBodyText>
                Tälle sivulle ilmeistyy linkki valokuvaajan kuvagalleriaan häiden jälkeen.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                <InstagramIcon style={{ paddingRight: '4px' }}></InstagramIcon>
            Instagram
            </StyledTypography>
            <StyledBodyText>
                Häiden virallinen hashtag on #prehtit2024.
                <br></br>
                <a
                    href={contextGlobalConfigs?.globalConfigs?.gallery_InstagramGalleryLink}
                    target="_blank"
                    rel="noreferrer"
                >Linkin</a> takaa löydät kaikki häistä postatut Instagram-kuvat.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                <img
                    style={{
                        height: '40px',
                        verticalAlign:'middle',
                        paddingRight: '3px'
                    }}
                    src='/img/gphotos.png'
                    alt='gphotos'
                >
                </img>
                    Google Photos
            </StyledTypography>
            <StyledBodyText>
                Tähän avoimeen <a
                    href={contextGlobalConfigs?.globalConfigs?.gallery_GooglePhotosGalleryLink}
                    target="_blank"
                    rel="noreferrer"
                >
                Google Photos -albumiin
                </a> voitte lisätä häistä ottamianne kuvia.
            </StyledBodyText>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
        </Container>
    );
}

export default GalleryPage;
