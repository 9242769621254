import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router-dom';
import { GlobalMessage, MessageContext } from '../App';
import { useContext } from 'react';

import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Accordion, AccordionSummary, AccordionDetails, styled } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const styleBase = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    maxWidth: '600px',
    bgcolor: 'background.paper',
    border: '2px solid',
    padding: '20px',
    boxShadow: 24,

    'border-radius': '8px'
}
const styleError = {
    ...styleBase,
    'border-color': ' rgb(187 0 0)',
};
const styleInfo = {
    ...styleBase
};

export enum MessageType {

    INFO,
    ERROR,
    SESSION_EXPIRED
}

export type GlobalMessageModalProps = GlobalMessage;

export default function GlobalMessageModal(props: GlobalMessageModalProps) {

    const contextMessage = useContext(MessageContext);

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const hasMessage = Object.keys(props).length > 0;

    const selectedStyle = props.type == MessageType.ERROR ? styleError : styleInfo;

    /* TODO: improve error handling by proper error codes */
    // FIXME: new message type session_expired
    const requiresRelogin =  typeof props?.details == 'string' && props?.details?.indexOf('expired') >= 0;

    const navigate = useNavigate();

    let message = props.message;

    // Custom messages
    if(requiresRelogin) {

        message = 'Istuntosi on vanhentunut. Kirjaudu sisään uudelleen.';
    }

    const handleRelogin = () => {

        contextMessage.setGlobalMessage(undefined);
        navigate('/logout0', {replace: true});
    }

    const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div>
            <Modal open={hasMessage}>
                <Box sx={selectedStyle}>
                    {props?.type == MessageType.ERROR && !requiresRelogin ?
                        <>
                            <WarningAmberIcon style={{
                                'verticalAlign':'middle',
                                color: 'rgb(187 0 0 / 22%)',
                                position: 'absolute',
                                fontSize: '12rem',
                                zIndex: '-10',
                                top: 0
                            }} ></WarningAmberIcon>
                            <Typography style={{ color: '#bb0000'}} id="modal-modal-title" variant="h4" component="h2">
                                {props.title || 'Virhe'}
                            </Typography>
                        </>
                        :
                        <>
                            <Typography style={{ color: '#000000'}} id="modal-modal-title" variant="h4" component="h2">
                                {props.title || 'Ilmoitus'}
                            </Typography>
                            <InfoOutlinedIcon style={{
                                'verticalAlign':'middle',
                                color: 'rgb(0 99 253 / 22%)',
                                position: 'absolute',
                                fontSize: '12rem',
                                zIndex: '-10',
                                top: 0
                            }} ></InfoOutlinedIcon>
                        </>
                    }
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {message ?? props.message ?? '(tyhjä)'}
                    </Typography>
                    <Accordion
                        expanded={expanded === 'panel1'}
                        onChange={handleChange('panel1')}
                        style={{
                            background: '#FFFFFF00',
                            borderRadius: '0px',
                            boxShadow: '0px 0px 0px 0px',
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            style={{
                                paddingLeft: '0px',
                            }}
                        >
                            <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 600 }}>
                                Lisätietoja
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                padding: '0px',
                            }}>
                            {
                                props.childContent ||
                                <code>
                                    {props.details || '(tyhjä)'}
                                </code>
                            }
                        </AccordionDetails>
                    </Accordion>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                    </Typography>
                    <div style={{ textAlign: 'center'}}>
                        {requiresRelogin ?
                            <Button
                                style={{marginTop: '20px'}}
                                variant='contained'
                                onClick={handleRelogin}>
                                Kirjaudu
                            </Button>
                            :
                            <Button
                                style={{marginTop: '20px'}}
                                variant='contained'
                                onClick={() => contextMessage.setGlobalMessage(undefined)}
                            >
                                Ok
                            </Button>
                        }
                    </div>
                </Box>
            </Modal>
        </div>
    );
}