
import { WeddingPageAPIClient } from './WeddingPageAPIClient';

import { ConfigurationService } from './ConfigurationService';
import { AuthService } from './AuthService';

export class WeddingPageAPIClientBase {

    protected getBaseUrl(param: string): string {

        return '';
    }

    protected transformOptions(options: any) {

        const token = AuthService.GetToken();
        options.headers.Authorization = `Bearer ${token}`;
        return Promise.resolve(options);
    }
}
