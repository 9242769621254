/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from '@emotion/styled';
import { FormControl } from '@mui/material';

const StyledFormControl = styled(FormControl)<any>(({ theme } : any) => ({
    ...theme,
    color: 'red',
    width: '19ch',
    m: {
        width: '30ch'
    }
}));

export default function Component(props: any) {
    return <StyledFormControl {...props}/>;
}
