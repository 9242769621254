import React from 'react';
import { Button, CircularProgress, Container, FormControl, IconButton, Input, InputAdornment, InputLabel, LinearProgress, TextField, Typography } from '@mui/material';

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import StyledTextField from '../component/StyledTextField';

import { Link as MuiLink } from '@mui/material';

import { AuthCommand, AuthResponse } from '../services/WeddingPageAPIClient';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';
import './LoginPage.css';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { VisibilityOff } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import StyledTypography from '../component/StyledTypography';
import StyledBodyText from '../component/StyledBodyText';
import StyledLoginPageFormControl from '../component/StyledLoginPageFormControl';

export type LoginPageProps = {

    setAuthenticated: (token: string|undefined) => void
}

const LoginPage = (props: LoginPageProps) => {

    const [formData, setFormData] = useState<AuthCommand|object>({});
    const [loginError, setLoginError] = useState<string|null|any>(null);
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    // const [isValidForm, setIsValidForm] = useState<boolean>(false);

    const [fieldErrors, setFieldErrors] = useState<any|null>({});

    const translateLoginError = (err: string) => {

        if(err?.indexOf('Missing required parameter FirstName') == 0) {

            setFieldErrors({ firstName: true});
            return 'Etunimi puuttuu';
        }

        if(err?.indexOf('Missing required parameter LastName') == 0) {

            setFieldErrors({ lastName: true});
            return 'Sukunimi puuttuu';
        }

        if(err?.indexOf('Missing required parameter Password') == 0) {

            setFieldErrors({ password: true});
            return 'Salasana puuttuu';
        }

        if(err?.indexOf('Wrong username or password') == 0) {

            setFieldErrors({ generic: true});
            return 'Väärä käyttäjänimi tai salasana';
        }

        setFieldErrors({ generic: true});
        return err;
    }

    const handleOnLoginClick = useCallback(async () => {

        setFieldErrors(null);
        setLoginError(null);
        setIsLoggingIn(true);
        const client = WeddingPageAPIClient.GetInstance();
        client.authenticate(formData as AuthCommand).then((res: AuthResponse) => {

            props.setAuthenticated(res.token);

        }).catch((error: Error & { response: any }) => {

            setFieldErrors(null);
            console.error(error);
            const errorMsg = translateLoginError(error.response.error || error.response || error.message || error);
            setLoginError(errorMsg);

        }).finally(() => setIsLoggingIn(false));

    }, [formData]);

    const handleShowPasswordClick = useCallback(async () => {

        setShowPassword(!showPassword);
    }, [showPassword]);

    return (
        <>

            <Container className='LoginPage'>
                <form>
                    <div>
                        <StyledLoginPageFormControl
                            error={!!fieldErrors?.firstName}
                            variant="standard">
                            <div className='LoginImgTopContainer'>
                                <img
                                    className='LoginImgTop'
                                    alt='login-top'
                                    src='/img/blue_berry1.png'
                                ></img>
                            </div>
                            <InputLabel>Etunimi</InputLabel>
                            <Input

                                disabled={isLoggingIn}
                                required
                                defaultValue=""
                                id="firstName"
                                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    setFormData({...formData, [evt.target.id]: evt.target?.value || null}) }
                                onKeyUp={evt => evt.code === 'Enter' && handleOnLoginClick()}
                                type='text'
                            />

                        </StyledLoginPageFormControl>
                        <br></br>
                        <StyledLoginPageFormControl error={fieldErrors?.lastName} variant="standard" sx={{ m: 1, width: '25ch' }}>
                            <InputLabel>Sukunimi</InputLabel>
                            <Input
                                disabled={isLoggingIn}
                                required
                                defaultValue=""
                                id="lastName"
                                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    setFormData({...formData, [evt.target.id]: evt.target?.value || null}) }
                                onKeyUp={evt => evt.code === 'Enter' && handleOnLoginClick()}
                                type='text'
                            />
                        </StyledLoginPageFormControl>
                        <br></br>
                        <StyledLoginPageFormControl
                            error={fieldErrors?.password}
                            variant="standard"
                        >
                            <div className='LoginImgBottomContainer'>
                                <img
                                    className='LoginImgBottom'
                                    alt='login-top'
                                    src='/img/blue_berry2.png'
                                ></img>
                            </div>
                            <InputLabel>Salasana</InputLabel>
                            <Input
                                disabled={isLoggingIn}
                                required
                                id="password"
                                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                                    setFormData({...formData, [evt.target.id]: evt.target?.value || null}) }
                                onKeyUp={evt => evt.code === 'Enter' && handleOnLoginClick()}
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPasswordClick}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />

                        </StyledLoginPageFormControl>
                        <br></br>
                        <div style={{visibility: loginError ? 'visible' : 'hidden'}}>
                            <StyledBodyText  variant="body1" component="span">
                        Virhe kirjautumisessa:
                            </StyledBodyText>
                            <br></br>
                            <StyledBodyText  variant="body1" component="span">
                                {loginError || '-'}
                            </StyledBodyText>
                        </div>
                        <div
                            style={{
                                visibility: isLoggingIn ? 'visible' : 'hidden',
                                display:'flex',
                                justifyContent: 'center',
                                margin: 4
                            }}
                        >
                            <LinearProgress style={{width: '25%'}}/>
                        </div>
                    </div>
                    <div>
                        <Button disabled={isLoggingIn}
                            variant='contained'
                            onClick={handleOnLoginClick}
                        >
                    Kirjaudu
                        </Button>
                    </div>
                </form>
            </Container>
        </>
    );
}

export default LoginPage;