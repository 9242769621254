/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

const StyledBodyText = styled(Typography)<any>(({ theme } : any) => ({
    ...theme,
    'fontFamily': 'Lora',
    marginTop: '10px',
    marginBottom: '15px'
}));

export default function Component(props: any) {
    return <StyledBodyText {...props}/>;
}
