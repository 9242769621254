import React, { useContext } from 'react';
import { Container, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import StyledTypography from '../component/StyledTypography';
import StyledBodyText from '../component/StyledBodyText';

import { GlobalConfigContext } from '../App';

const FaqPage = () => {

    // Context
    const contextGlobalConfigs = useContext(GlobalConfigContext);

    const contact3_Phone =  contextGlobalConfigs.globalConfigs?.contact3_Phone;
    const contact4_Phone =  contextGlobalConfigs.globalConfigs?.contact4_Phone;

    return (
        <Container className="fade-in-image" style={{minHeight: '50vh'}}>
            <StyledTypography variant='h3'>
                Usein Kysytyt Kysymykset
            </StyledTypography>
            <StyledBodyText>
                Tälle sivulle on koottu vastauksia yleisimpiin kysymyksiin hääpäiväämme liittyen. Jos ette löydä vastauksia näiltä sivulta, voitte olla yhteydessä hääpariin.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Missä tapasitte?
            </StyledTypography>
            <StyledBodyText>
                Ensikohtaaminen tapahtui rakkauden sovelluksessa Tinderissä vuonna 2016 Ruisrockin aikaan. Kun Eetu laittoi Anulle ensimmäisen viestin, soi taustalla Ellinooran Rakkauden kesä.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Kuka kosi ja miten?
            </StyledTypography>
            <StyledBodyText>
                Kosinta tapahtui syyskuussa 2022, kun matkustimme Ranskaan. Ajoimme yhdessä Pariisista Reimsiin, samppanjan kotiseudulle. Pitkän ja vaiherikkaan matkustuspäivän jälkeen Eetu halusi esitellä Anulle uudet nettisivut, joita oli ehtinyt valmistella jo pitkään. Sivujen viimeisestä osiosta löytyi tärkeä kysymys, johon Anu vastasi tahdon.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Ketkä kuuluvat häätiimiin?
            </StyledTypography>
            <StyledBodyText>
                Kaason kunniatehtävän ovat saaneet Anun sisko Maria, lapsuudenkaveri Kepa sekä opiskelukaverit Elli, Laura ja Sinikka. Bestmaneiksi Eetu puolestaan on pyytänyt veljiään Veliä, Joonaa ja Tatua, lapsuudenkaveriaan Juhoa sekä opiskelukaveriaan Ernoa.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Voimmeko paluukuljetuksen yhteydessä pysähtyä matkan varrella?
            </StyledTypography>
            <StyledBodyText>
                Tottakai! Olettehan asiasta etukäteen yhteydessä hääpariin, jotta osaamme kertoa bussikuskille toiveestanne.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Haluaisin pitää häissä puheen, onko se mahdollista ja kenelle kerron toiveestani?
            </StyledTypography>
            <StyledBodyText>
                Kuulisimme mielellämme ajatuksianne puheiden muodossa. Ennakkoon voitte kertoa toiveestanne pitää puhe kaasolle {contact3_Phone} tai bestmanille {contact4_Phone}.
            </StyledBodyText>
            <StyledTypography variant='h4'>
                Kuka on ottanut hääsivujen kauniit valokuvat?
            </StyledTypography>
            <StyledBodyText>
                Anun ystävä Hanna-Maija. Kuvauspaikka löytyi Ruissalosta.
            </StyledBodyText>

            <StyledTypography variant='h4'>
                Hääsivut
            </StyledTypography>
            <StyledBodyText>
                Nämä hääsivut on toteutettu alusta alkaen itse koodaamalla. Käyttöliittymä, eli front end, on toteutettu käyttäen seuraavia tekniikoita: React, TypeScript ja Material UI. Palvelinta, eli backendia pyörittää .NET6 frameworkilla toteutettu Azure Functions -sovellus, sekä tietokantana toimii Azuren DataStorage Tables. Sivut toimivat Microsoftin tarjoamassa Azure-pilvipalvelussa. Eetu kertoo kysyttäessä mielellään tarkemmin sivujen toteutuksesta ja toiminnasta.
                <br></br>
                <br></br>
                Sovelluksen rakenne
                <br></br>
                <br></br>
                <img
                    style={{ maxWidth: '80%' }}
                    src = "./img/System layout-Layout.drawio.svg"
                    alt="System layout-Layout.drawio.svg"
                />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
            </StyledBodyText>
        </Container>
    );
}

export default FaqPage;