
import * as React from 'react';

import './StyledAppBar.css';
import { AppBar, AppBarProps } from '@mui/material';

export default function Component(props: AppBarProps) {
    return (<AppBar
        style={{background: 'inherit', color: 'inherit' }}
        className='StyledAppBar' {...props}
    />)
}
