import { isDev } from "..";

import configurationDev from '../configuration.Development';
import configuration from '../configuration';

export class Configuration {

    APIBaseURL!: string;
}

export class ConfigurationService {

    public static GetConfiguration(): Configuration {

        if(isDev()) {

            return configurationDev;
        }

        return configuration;
    }
}