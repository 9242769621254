import * as React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import styled from '@emotion/styled';

const StyledTextField = styled(TextField)<TextFieldProps>(({ theme } :any) => ({
    ...theme,
    'fontFamily': 'Allura',
    backgroundColor: '#ffffff'
}));

export default function Component(props: TextFieldProps) {
    return <StyledTextField style={{fontFamily: 'Lora'}} {...props}/>;
}
