
import * as React from 'react';
import {  Paper, PaperProps } from '@mui/material';

export default function Component(props: PaperProps) {
    return (
        <Paper
            style={{backgroundColor: '#efefef' }}
            {...props}
        />)
}
