import * as React from 'react';
import { UserDataExtendedDto } from '../services/WeddingPageAPIClient'
import UsersSummaryTable, { UsersSummaryTableProps, UsersSummaryTableRow } from './UsersSummaryTable';

export type UsersSummaryProps = {

    users: UserDataExtendedDto[]|null
}

export default function UsersSummary(props: UsersSummaryProps) {

    const totalCount = (users: UserDataExtendedDto[]|null) => users ? users.length : Number.NaN;

    const calculateAttending = ( users: UserDataExtendedDto[]|null) => {

        if(!users) {
            return Number.NaN;
        }
        const count = users.filter(u => u.isAttending).length;
        return count;
    };

    const calculateAnswers = ( users: UserDataExtendedDto[]|null) => {

        if(!users) {
            return Number.NaN;
        }
        const count = users.filter(u => u.isAttending != null).length;
        return count;
    };

    const calculateBusToWeddingPlace = ( users: UserDataExtendedDto[]|null) => {

        if(!users) {
            return Number.NaN;
        }
        const count = users.filter(u => u.busToWeddingPlace).length;
        return count;
    };

    const calculateBusToCity = ( users: UserDataExtendedDto[]|null) => {

        if(!users) {
            return Number.NaN;
        }
        const count = users.filter(u => u.busToCity).length;
        return count;
    };

    const calculateLoggedInUsers = ( users: UserDataExtendedDto[]|null) => {

        if(!users) {
            return Number.NaN;
        }
        const count = users.filter(u => u.loginCount! > 0).length;
        return count;
    };

    const data: UsersSummaryTableRow[] = [

        {
            header: 'Vastauksia',
            positiveCount: calculateAnswers(props.users),
            totalCount: totalCount(props.users),
            answerCount: calculateAnswers(props.users)
        },
        {
            header: 'Osallistumassa',
            positiveCount: calculateAttending(props.users),
            totalCount: totalCount(props.users),
            answerCount: calculateAnswers(props.users)
        },
        {
            header: 'Kuljetus juhlapaikalle',
            positiveCount: calculateBusToWeddingPlace(props.users),
            totalCount: totalCount(props.users),
            answerCount: calculateAnswers(props.users)
        },
        {
            header: 'Kuljetus kaupunkiin',
            positiveCount: calculateBusToCity(props.users),
            totalCount: totalCount(props.users),
            answerCount: calculateAnswers(props.users)
        },
        {
            header: 'Sivuille kirjautuneet ',
            positiveCount: calculateLoggedInUsers(props.users),
            totalCount: totalCount(props.users),
            answerCount: calculateAnswers(props.users)
        }
    ];

    return (<UsersSummaryTable rows={data}></UsersSummaryTable>);
}
