import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Link as MuiLink,
    Popover,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';

import React, { useContext, useEffect } from 'react';
import { UserDataDto } from '../services/WeddingPageAPIClient';
import { Link } from 'react-router-dom';
import StyledPaper from './StyledPaper';
import StyledTextField from './StyledTextField';

export default function RSVPGuestForm (
    guest: UserDataDto,
    isDisabled: boolean,
    updateGuest: (guest: UserDataDto|any) => void,
    anchorEl: HTMLButtonElement|null,
    handleClick: (event: React.MouseEvent<any>) => void,
    setIsFormDirty: (dirtiness: boolean) => void,
    handleClose: (event: React.MouseEvent<any>) => void
) {

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const SPECIAL_DIETS_MAX_LENGTH = 100;

    return (
        <Grid
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            key={guest.firstName! + guest.lastName!}
            item
            xs={12}
            sm={6}
            md={4}
        >
            <StyledPaper>
                <Typography variant='h5'>
                    {guest.firstName} {guest.lastName}
                </Typography>
                <FormLabel id="demo-radio-buttons-group-label">Ilmoittautuminen</FormLabel>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={
                            guest.isAttending == true
                                ? 'attending'
                                : guest.isAttending == false
                                    ? 'not_attending'
                                    : 'no_data'
                        }
                        name="radio-buttons-group"
                        onChange={(_, selected) => {

                            setIsFormDirty(true);
                            updateGuest({
                                ...guest,
                                isAttending: selected === 'attending' ? true : selected === 'not_attending' ? false : undefined,
                            })
                        }}
                    >
                        <FormControlLabel
                            value="attending"
                            control={<Radio disabled={isDisabled}/>}
                            label="Osallistun"

                        />
                        <FormControlLabel
                            value="not_attending"
                            control={<Radio disabled={isDisabled}/>}
                            label="En osallistu"
                        />
                        {/* <FormControlLabel value="no_data" disabled control={<Radio />} label="Ei ilmoitettu" /> */}
                    </RadioGroup>
                    <FormLabel id="demo-radio-buttons-group-label">
                        Bussikuljetus&nbsp;
                        <MuiLink style={{cursor: 'pointer'}} onClick={handleClick}>(?)</MuiLink>
                    </FormLabel>
                    <FormControlLabel
                        control={<Checkbox

                            onChange={(newValue) => {

                                setIsFormDirty(true);
                                updateGuest({
                                    ...guest,
                                    busToWeddingPlace: newValue.target.checked
                                })
                            }}
                            checked={guest.busToWeddingPlace}/>}
                        label='Juhlapaikalle'
                        disabled={!guest.isAttending || isDisabled}
                    />
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(newValue) => {
                                setIsFormDirty(true);
                                updateGuest({
                                    ...guest,
                                    busToCity: newValue.target.checked
                                })
                            }}
                            checked={guest.busToCity}/>}
                        label='Kaupunkiin&nbsp;&nbsp;&nbsp;'
                        disabled={!guest.isAttending || isDisabled}
                    />
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Typography sx={{ p: 2 }}>
                        Häävieraille on järjestetty bussikuljetus kirkolta juhlapaikalle
                        ja illlalla takaisin Turun keskustaan.
                        Katso tarkemmat tiedot kuljetuksesta <Link to='/info'>Etusivulta</Link>.
                        </Typography>
                    </Popover>
                    <StyledTextField
                        style={{ }}
                        value={guest.specialDiets}
                        disabled={!guest.isAttending || isDisabled}
                        id="outlined-textarea"
                        label='Erityisruokavaliot ja allergiat'
                        placeholder=""
                        inputProps={{ maxLength: SPECIAL_DIETS_MAX_LENGTH }}
                        multiline
                        onChange={(newValue) => {
                            setIsFormDirty(true);
                            updateGuest({
                                ...guest,
                                specialDiets: newValue.target.value
                            })
                        }}
                    />
                </Grid>
                <br></br>
            </StyledPaper>
        </Grid>
    );
}