export class AuthService {

    public static GetToken(): string|null {

        return localStorage.getItem('APIToken');
    }

    public static SetToken(token: string){

        localStorage.setItem('APIToken', token);
    }

    public static DeleteToken() {

        localStorage.removeItem('APIToken');
    }

    public static IsAuthenticated(): boolean {

        return !!AuthService.GetToken();
    }
}
