import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export type UsersSummaryTableRow = {

    header: string,
    positiveCount: number,
    totalCount: number
    answerCount: number
};

export type UsersSummaryTableProps = {

    rows: UsersSummaryTableRow[]
};

export default function UsersSummaryTable(props: UsersSummaryTableProps) {

    const totalPercentage = (row: UsersSummaryTableRow) => {

        return Math.round(10 * row.positiveCount / row.totalCount * 100) / 10;
    }

    const answeredPercentage = (row: UsersSummaryTableRow) => {

        return Math.round(10 * row.positiveCount / row.answerCount * 100) / 10;
    }

    return (
        <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        <TableCell>Otsikko</TableCell>
                        <TableCell align="right">Kutsutuista</TableCell>
                        <TableCell align="right">Ilmoittautuneista</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows.map((row) => (
                        <TableRow
                            key={row.header}
                        >
                            <TableCell align="left">{row.header}</TableCell>
                            <TableCell align="right">{row.positiveCount} / {row.totalCount} ({totalPercentage(row)}%)</TableCell>
                            <TableCell align="right">{row.positiveCount} / {row.answerCount} ({answeredPercentage(row)}%)</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}