import React, { useContext, useState } from 'react';
import { Grid, Typography, FormControl, InputLabel, Input, Button, Container, LinearProgress } from '@mui/material'
import StyledBodyText from '../component/StyledBodyText';
import { UserDataDto, UserDataUpdateCommand } from '../services/WeddingPageAPIClient';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';
import { MessageContext } from '../App';
import { MessageType } from '../component/GlobalMessageModal';

const ProfilePage = () => {

    const contextMessage = useContext(MessageContext);

    const [formData, setFormData] = useState<UserDataUpdateCommand|object>({} as any);
    const [isSubmittingForm, setIsSubmittingForm] = useState<boolean>(false);

    const handleSubmit = () => {

        if((formData as any).password?.length < 8) {

            return;
        }
        setIsSubmittingForm(true);
        const client = WeddingPageAPIClient.GetInstance();
        client.setPassword(formData as UserDataUpdateCommand).then((res: UserDataDto) => {

            contextMessage.setGlobalMessage({
                title: 'Tiedot päivitetty onnistuneesti!',
                message: '',
                type: MessageType.INFO
            });

        }).catch((error: Error & { response: any }) => {

            contextMessage.setGlobalMessage({
                title: undefined,
                message: 'Virhe tallennettaessa käyttäjän tietoja!',
                details: JSON.stringify(error.response || error.message, null, 2),
                type: MessageType.ERROR
            })

        }).finally(() => setIsSubmittingForm(false));

    }

    return (
        <Container>

            <Grid item xs={12} style={{marginBottom: '20px'}}>
                <Typography variant='h4'>
                Profiili
                </Typography>
                <StyledBodyText>
                    Voit halutessasi asettaa turvallisemman salasanan tämän sivun kautta.
                </StyledBodyText>
                <FormControl variant="standard">
                    <InputLabel>Salasana</InputLabel>
                    <Input
                        disabled={isSubmittingForm}
                        id="password"
                        onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
                            setFormData({...formData, [evt.target.id]: evt.target?.value || null}) }
                        onKeyUp={evt => evt.code === 'Enter' && handleSubmit()}
                        type='text'
                    />
                </FormControl>
                <div
                    style={{
                        visibility: isSubmittingForm ? 'visible' : 'hidden',
                        display:'flex',
                        justifyContent: 'center',
                        margin: 4
                    }}
                >
                    <LinearProgress style={{width: '25%'}}/>
                </div>
                <Typography variant='body1' style={{margin: '30px'}}>
                    <Button
                        disabled={!(formData as any).password?.length || (formData as any).password?.length < 8 || isSubmittingForm}
                        variant='contained'
                        onClick={handleSubmit}
                    >
                            Aseta salasana
                    </Button>
                </Typography>
            </Grid>
        </Container>
    );
};

export default ProfilePage;