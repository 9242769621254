
import { WeddingPageAPIClient } from './WeddingPageAPIClient';

import { ConfigurationService } from './ConfigurationService';

class WeddingPageAPIClientExtended extends WeddingPageAPIClient {

    public static GetInstance(): WeddingPageAPIClient {

        const config = ConfigurationService.GetConfiguration();

        return new WeddingPageAPIClient(config.APIBaseURL);
    }
}

export { WeddingPageAPIClientExtended };