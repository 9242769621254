import React from 'react';
import { Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

const WorkInProgressPage = () => {

    return (
        <>
            <Typography variant='h3'>
                Work in progress . . .
                <br></br>
                <ConstructionIcon style={{scale: '300%'}}></ConstructionIcon>
            </Typography>
        </>
    );
}

export default WorkInProgressPage;