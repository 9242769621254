import { Button, CircularProgress, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { GlobalConfigContext, LoadingContext, MessageContext, UserContext } from '../App';
import NavigationPanel from '../component/NavigationPanel';
import StyledAppBar from '../component/StyledAppBar';
import StyledTypography from '../component/StyledTypography';
import LogoutIcon from '@mui/icons-material/Logout';
import { Icon } from '@mui/material';

import './MainPage.css';
import GlobalMessageModal, { MessageType } from '../component/GlobalMessageModal';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';
import { UserDataDto } from '../services/WeddingPageAPIClient';
import { AxiosError } from 'axios';

const NAVIGATION_HIDE_THRESHOLD = 70;

const MainPage = () => {

    const [smallNavBar, setSmallNavBar] = useState(false)

    const contextLoading = useContext(LoadingContext);
    const contextMessage = useContext(MessageContext);
    const contextUser = useContext(UserContext);
    const contextGlobalConfigs = useContext(GlobalConfigContext);

    const controlNavbar = () => {

        // console.log('window.scrollY', window.scrollY);
        if (window.scrollY > NAVIGATION_HIDE_THRESHOLD ) {
            setSmallNavBar(true);
        }else{
            setSmallNavBar(false);
        }
    }

    const client = WeddingPageAPIClient.GetInstance();

    useEffect(() => {

        contextLoading.setLoading({isLoading: true, message: 'Ladataan'});

        Promise.all([client.getGlobalConfigs(), client.getSelf()])

            .then(([globalConfigs, userData]) => {

                contextUser.setUserData(userData);
                contextGlobalConfigs.setGlobalConfigs(globalConfigs);

            }).catch((error: AxiosError) => {

                contextMessage.setGlobalMessage({
                    title: undefined,
                    message: 'Virhe haettaessa käyttäjän tietoja!',
                    details: JSON.stringify(error.response || error.message, null, 2),
                    type: MessageType.ERROR
                })
            }).finally(() => {

                contextLoading.setLoading({isLoading: false});
            });
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, []);

    const location = useLocation();
    const path = location.pathname;

    const navigate = useNavigate();

    const onLogOutClick = () => {

        navigate('/logout', {replace: true});
    }

    const UserName = (userData: UserDataDto|undefined) =>
        userData ? `${userData?.firstName} ${userData?.lastName}${userData?.isAdmin ? ' (Adm)' : ''}` : '-';

    return (
        <Container maxWidth={false} style={{height: '100%', padding: '0px'}}>
            <GlobalMessageModal {...contextMessage.globalMessage} />
            <StyledAppBar style={{color: 'inherit'}}>
                <Grid container

                    justifyContent="space-between"
                    alignItems="center"
                    className={smallNavBar ? 'expandable': 'expanded'}
                >
                    <Grid
                        xs={4}
                        sm={4}
                        md={4}
                        item
                    ></Grid>
                    <Grid
                        xs={4}
                        sm={4}
                        md={4}
                        item
                    >
                        <img
                            style={{
                                width: '100px',
                                margin: '6px'
                            }}
                            alt='calligraphy'
                            src='/img/calligraphy - prd.png'
                        >
                        </img>
                    </Grid>
                    <Grid
                        className='logoutContainer'
                        item
                        xs={4}
                        sm={4}
                        md={4}>
                        <Typography style={{

                        }}>
                            {UserName(contextUser?.userData)}
                        </Typography>
                        <Button
                            variant='text'
                            onClick={onLogOutClick}
                        >
                            <LogoutIcon/>
                            Ulos
                        </Button>
                    </Grid>
                </Grid>
                <NavigationPanel path={path} smallNavBar={smallNavBar}/>
            </StyledAppBar>
            <div style={{position: 'relative', top: '200px'}}>
                <Outlet/>
                {contextLoading.loading?.isLoading ?
                    <div className="outer">
                        <div className="middle">
                            <div className="inner">
                                <StyledTypography variant="h3"
                                    style={{
                                        fontSize: '4em'
                                    }}
                                >
                                    {contextLoading.loading.message}. . .
                                </StyledTypography>
                                <CircularProgress />
                                <br></br>
                            </div>
                        </div>
                    </div>
                    : null
                }
            </div>
        </Container>
    );
}

export default MainPage;