import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { UserDataDto } from '../services/WeddingPageAPIClient';
import { Paper, Typography, styled } from '@mui/material';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import StyledBodyText from './StyledBodyText';

export const TableCellSlim = styled(TableCell)<any>(({ theme } : any) => ({
    ...theme,
    padding: '2px'
}));

export const TableCellBolded = styled(TableCellSlim)<any>(({ theme } : any) => ({
    ...theme,
    fontWeight: 700
}));



export default function UserDataTable (title: string, rows: UserDataDto[]) {

    const ToIcon = (value: boolean|undefined) => {

        if(value == true) {

            return <CheckCircleOutlineIcon style={{color: '#2e7d32'}}/>
        }
        if(value == false) {

            return <DoDisturbOnOutlinedIcon style={{color: 'rgb(181 0 0)'}}/>
        }

        return <HelpOutlineOutlinedIcon  style={{color: 'rgb(161 161 161)'}}/>;
    }

    return (
        <>
            <StyledBodyText style={{ color: '#000000'}} id="modal-modal-title">
                {title || 'Ilmoitus'}
            </StyledBodyText>
            <TableContainer style={{backgroundColor: 'rgba(255, 255, 255, 0.7)'}} component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCellBolded>Nimi</TableCellBolded>
                            <TableCellBolded align="center">Osallist.</TableCellBolded>
                            <TableCellBolded align="center">Kuljetus</TableCellBolded>
                            <TableCellBolded align="left">Ruokavalio</TableCellBolded>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (

                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCellSlim style={{padding: '2px 2px 2px 8px'}}>{row.firstName}</TableCellSlim>
                                <TableCellSlim align="center">{ToIcon(row.isAttending)}</TableCellSlim>
                                <TableCellSlim align="center">
                                    {ToIcon(!!row.busToWeddingPlace)}
                                    <span style={{fontSize: '2em'}}>/</span>
                                    {ToIcon(!!row.busToCity)}
                                </TableCellSlim>
                                <TableCellSlim align="left">{row.specialDiets || '—'}</TableCellSlim>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
