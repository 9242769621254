import React, { useEffect, useState } from 'react';
import { CircularProgressbar, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { useNavigate } from 'react-router-dom';
import StyledTypography from '../component/StyledTypography';

import 'react-circular-progressbar/dist/styles.css';
import './LogoutPage.css';
import { Button } from '@mui/material';

const COUNTER_SECS = 3;
const INTERVAL_MS = 100;

export type LogoutPageProps = {

    setAuthenticated: (token: string|undefined) => void,
    logoutTimeSecs: number,
};

export function LogoutPage(props: LogoutPageProps) {

    const [logOutCount, setLogOutCount] = useState(props.logoutTimeSecs ?? COUNTER_SECS);
    const navigate = useNavigate();

    // Instant logout
    if(props.logoutTimeSecs === 0) {

        props.setAuthenticated(undefined);
        navigate('/login', {replace: true});
        return(<></>);
    }

    useEffect(() => {

        const interval = setInterval(() => {

            if(logOutCount < -0.7) {
                props.setAuthenticated(undefined);
                navigate('/login', {replace: true});
            } else{

                /* console.log('logOutCount', logOutCount); */
                setLogOutCount(logOutCount - INTERVAL_MS/1000);
            }
        }, INTERVAL_MS);

        return () => clearInterval(interval);
    }, [logOutCount]);

    return (
        <div className='LogoutPage'>
            <div style={{maxWidth: '200px', textAlign: 'center'}}>
                <CircularProgressbarWithChildren
                    value={100 * logOutCount/COUNTER_SECS}>
                    <StyledTypography style={{fontSize: '2em', lineHeight: '0.8', marginTop: '50px', paddingTop: '0px', paddingBottom: '0px'}}>Kirjaudutaan ulos. . .</StyledTypography>
                    <StyledTypography style={{fontSize: '3em', lineHeight: '1.5', paddingTop: '0px', paddingBottom: '0px'}}>{`${Math.ceil(logOutCount)}`} </StyledTypography>
                </CircularProgressbarWithChildren>
                <br></br>
                <Button
                    variant='contained'
                    onClick={() => navigate('/', {replace: true})}
                >
                    Peruuta
                </Button>
            </div>
        </div>);
}