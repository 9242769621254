import { Box, Tabs, Tab, Grid } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StyledTypography from './StyledTypography';
import { UserContext } from '../App';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <StyledTypography>{children}</StyledTypography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function LinkTab(props: any) {

    return (
        <Tab
            component={Link}
            //   onClick={(event) => {
            //     event.preventDefault();
            //   }}
            to={props.pathname}
            {...props}
        />
    );
}

const pages: {[index: string]: number} = {

    '/info': 0,
    '/rsvp': 1,
    '/gallery': 2,
    /* '/gifts': 3, */
    '/faq': 3,
    /* '/playlist': 4,
    '/thegame': 5, */
    '/admin': 4,
    '/profile': 5,
};

function MapPageToTabIndex(path: string): number {

    return pages[path] || 0;
}

export type NavigationPanelProps = {

  path: string;
  smallNavBar: boolean;
};

export default function NavigationPanel(props: NavigationPanelProps) {

    const contextUser = useContext(UserContext);

    const [value, setValue] = React.useState(MapPageToTabIndex(props.path));

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {

        setValue(MapPageToTabIndex(props.path));
    }, [props.path])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs"
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                    >
                        <LinkTab pathname="/info" label="Etusivu" {...a11yProps(0)} />
                        <LinkTab pathname="/rsvp" label="Ilmoittautuminen" {...a11yProps(1)} />
                        <LinkTab pathname="/gallery" label="Galleria" {...a11yProps(2)} />
                        {/* <LinkTab pathname="/gifts" label="Muistaminen" {...a11yProps(3)} /> */}
                        <LinkTab pathname="/faq" label="UKK" {...a11yProps(3)} />
                        {/* <LinkTab pathname="/playlist" label="Soittolista" {...a11yProps(4)} /> */}
                        {/* <LinkTab pathname="/thegame" label="Hääpeli" {...a11yProps(5)} /> */}
                        {contextUser.userData?.isAdmin
                            ?
                            [
                                <LinkTab key="admin" pathname="/admin" label="Admin" {...a11yProps(6)} />,
                                <LinkTab key="profile" pathname="/profile" label="Profiili" {...a11yProps(7)} />
                            ]
                            : null
                        }

                    </Tabs>
                </Box>
                {/* {props.children} */}
            </Grid>
        </Grid>
    );
}
