import {
    Box,
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Link as MuiLink,
    Popover,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { MessageContext, LoadingContext, GlobalConfigContext } from '../App';
import StyledPaper from '../component/StyledPaper';
import StyledTextField from '../component/StyledTextField';
import StyledTypography from '../component/StyledTypography';
import { RsvpCommand, UserDataDto, UserRSVPCommand } from '../services/WeddingPageAPIClient';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';

import RSVPGuestForm from '../component/RSVPGuestForm';

import './RSVPPage.css';
import { AxiosError } from 'axios';
import { MessageType } from '../component/GlobalMessageModal';
import StyledBodyText from '../component/StyledBodyText';
import { DateTime } from 'luxon';
import UserDataTable from '../component/RSVPModalTable';

type FormData = {

    guestData: UserDataDto[]|null;
    comments: string|undefined;
}

type RSVPPageProps = {

    users?: UserDataDto[];
    saveEventOk?: () => void;
}

const RSVPPage = (props: RSVPPageProps) => {

    // Context
    const contextMessage = useContext(MessageContext);
    const contextLoading = useContext(LoadingContext);
    const contextGlobalConfigs = useContext(GlobalConfigContext);

    const [formDirty, setIsFormDirty] = useState<boolean>(false);
    const COMMENTS_MAX_LENGTH = 150;
    const [formData, setFormData] = useState<FormData>({
        guestData: null,
        comments: undefined,
    });

    const client = WeddingPageAPIClient.GetInstance();

    const GetRSVPStatus = () => {

        if(props?.users) {
            return setFormData({
                ...formData,
                guestData: props?.users
            });
        }

        contextLoading.setLoading({isLoading: true, message: 'Ladataan'});
        client.getRSVPStatus().then((userData: UserDataDto[]) => {

            // TODO: set self as first
            // userData = userData.sort(a: UserData, b: UserData) =>
            // guestData: userData.sort((a, b) => a.id === contextUser.userData?.id ? Number.MAX_SAFE_INTEGER : a.lastName?.localeCompare(b.lastName ?? '')
            setFormData({
                ...formData,
                guestData: userData
            });
        }).catch((error: AxiosError) => {

            contextMessage.setGlobalMessage({
                title: undefined,
                message: 'Virhe haettaessa ilmoittautumistietoja!',
                details: JSON.stringify(error.response || error.message, null, 2),
                type: MessageType.ERROR
            })
        }).finally(() => {

            contextLoading.setLoading({isLoading: false});
        });
    }

    useEffect(GetRSVPStatus, []);

    const updateGuest = (guestData: UserDataDto) => {

        const guest = formData?.guestData?.find(_ => (_.firstName! + _.lastName!) === (guestData.firstName! + guestData.lastName!) )
        Object.assign(guest!, guestData);
        const newState = JSON.parse(JSON.stringify(formData));
        setFormData(newState);
    };

    const submitForm = async () => {

        contextLoading.setLoading({isLoading: true, message: 'Tallennetaan'});
        setIsFormDirty(false);

        await client.setRSVPStatus(new RsvpCommand({

            comments: formData.comments,
            users: formData.guestData?.map(_ => new UserRSVPCommand({

                id: _.id,
                firstName: _.firstName,
                lastName: _.lastName,
                busToCity: _.busToCity,
                busToWeddingPlace: _.busToWeddingPlace,
                specialDiets: _.specialDiets,
                isAttending: _.isAttending,
            }))

        })).then((res: UserDataDto[]) => {

            const content = UserDataTable('Ilmoittautumisesi tallennettiin seuraavilla tiedoilla:', res);
            contextMessage.setGlobalMessage({
                title: 'Ilmoittautuminen onnistui!',
                childContent: content,
                message: '',
                details: JSON.stringify(res),
                type: MessageType.INFO
            });

            GetRSVPStatus();
            props?.saveEventOk && props?.saveEventOk();

        }).catch((error: AxiosError) => {

            setIsFormDirty(true);
            contextMessage.setGlobalMessage({
                title: undefined,
                message: 'Virhe tallennettaessa ilmoittautumistietoja!',
                details: JSON.stringify(error.response || error.message, null, 2),
                type: MessageType.ERROR
            })

        }).finally(() => {
            contextLoading.setLoading({isLoading: false});
        });
    }

    // Info modal

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<any>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const now = DateTime.now();
    const deadlineReached = contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate == null
        ? null
        : (now > (contextGlobalConfigs?.globalConfigs?.rsvP_DeadlineDate as any));

    return (
        <FormGroup>
            <Box sx={{ flexGrow: 1 }}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                    spacing={2}
                >
                    {formData?.guestData?.map(_ =>
                        RSVPGuestForm(
                            _,
                            deadlineReached == true,
                            updateGuest,
                            anchorEl,
                            handleClick,
                            setIsFormDirty,
                            handleClose
                        ))
                    }
                    <Grid item xs={12}>
                        <TextField
                            style={{}}
                            minRows={3}
                            id="outlined-textarea"
                            label={`Vapaa sana (${formData?.comments?.length || '0'}/${COMMENTS_MAX_LENGTH})`}
                            placeholder=""
                            multiline
                            inputProps={{ maxLength: COMMENTS_MAX_LENGTH }}
                            disabled={!!deadlineReached}
                            onChange={(newValue) => {

                                setIsFormDirty(true);
                                setFormData({
                                    ...formData,
                                    comments: newValue.target.value
                                });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} mb={3}>
                        <Button
                            disabled={!!(!formDirty || deadlineReached)}
                            variant='contained'
                            onClick={submitForm}
                        >
                                Tallenna
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </FormGroup>
    );
}

export default RSVPPage;

