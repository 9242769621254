import React, { useContext, useEffect } from 'react';
import { Button, Checkbox, Container, FormControlLabel, Grid, Typography } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useState } from 'react';

import { MessageContext, LoadingContext } from '../App';
import { WeddingPageAPIClientExtended as WeddingPageAPIClient } from '../services/WeddingPageAPIClient.extensions';
import { AxiosError } from 'axios';
import { UserDataExtendedDto } from '../services/WeddingPageAPIClient';
import UsersTable from '../component/UsersTable';
import UsersSummary from '../component/UsersSummary';
import { MessageType } from '../component/GlobalMessageModal';
import RSVPGuestForm from '../component/RSVPGuestForm';
import RSVPPage from './RSVPPage';

type FormData = {

    userData: UserDataExtendedDto[]|null;
    extendedTableProps: boolean;
}

const AdminPage = () => {

    const contextMessage = useContext(MessageContext);
    const contextLoading = useContext(LoadingContext);

    const client = WeddingPageAPIClient.GetInstance();

    const [selectedUsers, setSelectedUsers] = useState<UserDataExtendedDto[]>([]);
    const [editSelectedUsers, setEditSelectedUsers] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData>({
        userData: null,
        extendedTableProps: true,
    });

    const handleUserSelectionChange = (selectedUsers: UserDataExtendedDto[]) => {

        setSelectedUsers(selectedUsers);
        console.log(selectedUsers);
    }

    const getAllUsers = () => {

        contextLoading.setLoading({isLoading: true, message: 'Ladataan'});
        client.getAllUsers().then((userData: UserDataExtendedDto[]) => {

            setFormData({
                ...formData,
                userData
            });
        }).catch((error: AxiosError) => {

            contextMessage.setGlobalMessage({
                title: undefined,
                message: 'Virhe haettaessa käyttäjiä!',
                details: JSON.stringify(error.response || error.message, null, 2),
                type: MessageType.ERROR,
            })
        }).finally(() => {

            contextLoading.setLoading({isLoading: false});
        });
    }

    const handleUsersEditEvent = () => {

        setEditSelectedUsers(false);
        setSelectedUsers([]);
        getAllUsers();
    }

    useEffect(getAllUsers, []);

    const updateExtProps = (val: boolean) => {
        setFormData({
            ...formData,
            extendedTableProps: val
        });
    };

    if(editSelectedUsers) {

        return (
            <Container>
                <Typography variant='h3'>
                Admin
                </Typography>
                <Typography variant='body1' style={{margin: '30px'}}>
                    <Button
                        variant='contained'
                        onClick={() => setEditSelectedUsers(false)}
                    >
                        Peruuta
                    </Button>
                </Typography>
                <RSVPPage saveEventOk={handleUsersEditEvent} users={selectedUsers}></RSVPPage>
            </Container>
        );
    }

    return (
        <Container>
            <Typography variant='h3'>
                Admin
            </Typography>
            <Grid
                container
                justifyContent="flex-start"
                style={{marginBottom: '20px'}}
            >
                <Grid item xs={12} style={{marginBottom: '20px'}}>
                    <Typography align='left' variant='h4'>
                       Yhteenveto
                    </Typography>
                </Grid>
                <Grid item justifyContent="flex-start" xs={12} style={{marginBottom: '20px'}}>
                    <UsersSummary users={formData.userData}></UsersSummary>
                </Grid>
                <Grid item justifyContent="flex-start" xs={12}>
                    <Typography align='left' variant='h4'>
                        Vieraat
                    </Typography>
                    <Typography variant='body1' style={{margin: '30px'}}>
                        <Button
                            disabled={(!selectedUsers) || selectedUsers?.length == 0 || selectedUsers?.length > 6}
                            variant='contained'
                            onClick={() => setEditSelectedUsers(true)}
                        >
                            Muuta tietoja
                        </Button>
                    </Typography>
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(newValue) => {
                                updateExtProps(newValue.target.checked)
                            }}
                            checked={formData.extendedTableProps}/>}
                        label='Lisätiedot'
                    />
                </Grid>
            </Grid>
            <UsersTable
                users={formData.userData}
                extendedProps={formData.extendedTableProps}
                setSelectedUsers={handleUserSelectionChange}
            ></UsersTable>
        </Container>
    );
}

export default AdminPage;