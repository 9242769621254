import React from 'react';
import {
    Container} from '@mui/material';
import { useContext } from 'react';
import { GlobalConfigContext } from '../App';
import StyledTypography from '../component/StyledTypography';

import StyledBodyText from '../component/StyledBodyText';
import { DateTime } from 'luxon';
import RSVPPage from './RSVPPage';

import './RSVPPageContainer.css';
import { Link } from 'react-router-dom';

const RSVPPageContainer = () => {

    // Context
    const contextGlobalConfigs = useContext(GlobalConfigContext);

    const deadlineStr = contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate
        ? contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate.toFormat('d.M.yyyy') : '(ei tietoa)';

    const now = DateTime.now();
    const deadlineReached = contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate == null
        ? null
        : (now > (contextGlobalConfigs?.globalConfigs?.rsvP_DeadlineDate as any));

    return (
        <Container className="fade-in-image" style={{minHeight: '50vh'}}>
            <StyledTypography variant='h2'>
                Ilmoittautuminen
            </StyledTypography>
            {deadlineReached == true ?
                <StyledBodyText>
                    Ilmoittautuminen on päättynyt <strong>{deadlineStr}</strong>.
                    Jos ilmoittautumistietoihinne on tullut tämän jälkeen muutoksia, olettehan suoraan yhteydessä hääpariin.
                </StyledBodyText>
                :
                <StyledBodyText>
                    Ilmoitattehan saapumisestanne <strong>{deadlineStr}</strong> mennessä.
                    <br></br>
                    <br></br>
                    Voitte vapaasti muokata ilmoittautumistietojanne tähän takarajaan saakka.
                    Jos tietoihin tulee tämän jälkeen muutoksia, olettehan suoraan yhteydessä hääpariin.
                    {/* Yhteystiedot löytyvät Etusivulta kohdasta <Link to='/info'>Yhteystiedot</Link>. */}
                </StyledBodyText>
            }
            <RSVPPage></RSVPPage>
        </Container>
    );
}

export default RSVPPageContainer;

