import React, { useContext } from 'react';
import { Container, Popover, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import StyledTypography from '../component/StyledTypography';
/* const FlipCountdown = require('@rumess/react-flip-countdown'); */

import './InformationPage.css';
import StyledBodyText from '../component/StyledBodyText';
import { GlobalConfigContext } from '../App';
import CopyToClipboardButton from '../component/CopyToClipboardButton';

const ruutiQuotes = [

    '...',
    'Hau!',
    'Vuh!!!',
    'Hei! Olen Ruuti!',
    'Kaikkeen sitä joutuu häiden vuoksi...',
    'Oispa luita...',
    'Onkohan sivulla vielä muita easter eggeja?'
];

const InformationPage = () => {

    // Context
    const contextGlobalConfigs = useContext(GlobalConfigContext);

    // Easter egg

    const [easterEggIndex, setEasterEggIndex] = React.useState<number>(0);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const deadlineStr = contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate
        ? contextGlobalConfigs.globalConfigs?.rsvP_DeadlineDate.toFormat('d.M.yyyy') : '(ei tietoa)';

    const contact1_Phone =  contextGlobalConfigs.globalConfigs?.contact1_Phone;
    const contact2_Phone =  contextGlobalConfigs.globalConfigs?.contact2_Phone;
    const contact3_Phone =  contextGlobalConfigs.globalConfigs?.contact3_Phone;
    const contact4_Phone =  contextGlobalConfigs.globalConfigs?.contact4_Phone;


    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<any>) => {

        const currentIndex = (easterEggIndex + 1) % ruutiQuotes.length
        setEasterEggIndex(currentIndex);
        setAnchorEl(event.currentTarget);

        const textLength = ruutiQuotes[currentIndex].length;
        setTimeout(handleClose, Math.max(1000, textLength * 1000 / 7));
    };

    return (
        <div>
            {/*
            <FlipCountdown
                endAt={'2024-08-31 00:00:00'} // Date/Time
            />
            */}
            <div className="fade-in-image">
                <StyledTypography style={{
                    position: 'absolute',
                    fontFamily: 'Jumpstraight',
                    width: '100%',
                    marginTop: '20vw',
                    color: 'white',
                    fontWeight: '500',
                    fontSize: '15vw',
                    textShadow: '3px 3px 2px grey'
                }} variant='h1'>
                    Anu ja Eetu
                </StyledTypography>
                {/*                 <StyledBodyText style={{
                    position: 'absolute',
                    width: '100%',
                    marginTop: '35vw',
                    color: 'white',
                    fontWeight: '300',
                    fontSize: '2.5vw',
                    textShadow: '3px 3px 2px grey'
                }} variant='h3'>
                    31.8.2023
                </StyledBodyText> */}
                <img
                    style={{
                        width: '100%',

                    }}
                    src='/img/Anu_and_Eetu3.jpg'
                    alt='AnujaEetu'
                >
                </img>
                <div className='easterEgg'
                    onClick={handleClick}
                >

                </div>
                <Popover
                    className='easterEggPopOver'
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: -30,
                        horizontal: 'center',
                    }}
                    style={{background:'#00000000'}}
                >
                    <h1>
                        {ruutiQuotes[easterEggIndex]}
                    </h1>
                </Popover>
            </div>
            <Container maxWidth="md">
                <StyledTypography variant='h1' style={{ paddingBottom: '0px', md: { fontSize: '8em', }}}>
                    Tervetuloa
                </StyledTypography>
                <StyledTypography variant='h3' style={{ paddingTop: '0px'}}>
                    Vihkiminen ja hääjuhla
                </StyledTypography>
                <StyledBodyText>
                    Meillä on suuri ilo ja kunnia kutsua teidät jakamaan kanssamme se tärkeä hetki, kun sanomme toisillemme tahdon lauantaina <strong>31.8.2024 klo 14</strong> Turun tuomiokirkossa.
                </StyledBodyText>
                <StyledBodyText>
                    Vihkimisen jälkeen jatkamme juhlia aikuisten kesken Villa Pähkinämäessä.
                </StyledBodyText>
                <StyledBodyText>
                    Vihkimisen ja hääjuhlan tulee ikuistamaan valokuvaaja. Toivomme, että vihkimisen aikana häävieraat eivät ottaisi itse kuvia. Sen sijaan hääjuhlan aikan kuvien ottaminen ja jakaminen on toivottua. Häiden jälkeen kuvat tulevat jakoon hääsivuille.
                </StyledBodyText>
                <StyledBodyText>
                    Ilmoitattehan meille osallistumisestanne <strong>{deadlineStr}</strong> mennessä oheisen linkin kautta:
                    <br></br>
                    <Link to='/rsvp'>Ilmoittaudu</Link>
                </StyledBodyText>
                <StyledTypography variant='h3'>
                    Saapuminen ja kuljetukset
                </StyledTypography>
                <StyledBodyText>
                    Vihkipaikka sijaitsee Turun keskustassa, osoitteessa <a target="_blank" rel="noreferrer" href='https://goo.gl/maps/g6Bzi911rpBjMcty7'>Tuomiokirkonkatu 1, 20500 Turku</a>, jonne on lyhyt kävely- tai bussimatka juna- ja linja-autoasemilta. Tuomiokirkon läheisyydessä on myös jonkin verran parkkipaikkoja, mutta kesäviikonloppuisin paikkoja on rajoitetusti ja paikan etsimiseen saattaa mennä aikaa.
                </StyledBodyText>
                <StyledBodyText>
                    Vihkimisen jälkeen siirrymme juhlapaikalle, joka sijaitsee noin 30 minuutin ajomatkan päässä Nousiaisissa. Olemme järjestäneet vieraillemme yhteiskuljetuksen kirkolta juhlapaikalle. Toivomme, että hyödynnätte tätä mahdollisuutta ja jätätte omat autonne kotiin juhliaksenne kanssamme myöhään yöhön asti. Paluukuljetus järjestetään vastaavasti juhlapaikalta klo 1.30 juhlien päättyessä takaisin Turun tuomiokirkolle. Kerrottehan halustanne osallistua yhteiskuljetukseen ilmoittautumisen yhteydessä.
                </StyledBodyText>
                <StyledBodyText>
                Mikäli haluatte saapua omalla autolla, löydätte juhlapaikan osoitteesta <a target="_blank" rel="noreferrer" href='https://goo.gl/maps/LEDWLkPaxia1sZmT6'>Halisojantie 60, 21270 Nousiainen</a>. Huomiottehan parkkipaikan sijainnin sekä sallitun ajoreitin alla olevasta kartasta. Parkkipaikalle saa jäädä yöksi asuntovaunulla/-autolla.
                </StyledBodyText>
                <img
                    style={{
                        width: '90vw',
                        maxWidth: '30em',
                        verticalAlign:'middle'
                    }}
                    src='/img/map_pahkinamaki2.png'
                    alt='map_pahkinamaki'
                >
                </img>
                <StyledTypography variant='h3'>
                    Majoitus
                </StyledTypography>
                <StyledBodyText>
                    Mikäli haluatte jäädä Turkuun yöksi, on kaupungissa erilaisia hotelleja ja muita majoituspaikkoja. Olemme kuitenkin hankkineet vieraitamme varten alennuskoodit alla oleviin Turun keskustan hotelleihin. Suosittelemme myös tarkistamaan hotellin oman päivän hinnan, joka saattaa olla halvempi kuin alekoodin hinta.
                </StyledBodyText>
                <StyledTypography variant='h4'>
                    Sokos Hotel Wiklund
                </StyledTypography>
                <StyledBodyText>
                    Käyttämällä koodia <strong>CELEBRATION</strong> saatte pienen alennuksen Sokos Hotel Wiklundista hääpäivänä. <a
                        href='https://www.sokoshotels.fi/fi/turku/original-sokos-hotel-wiklund'
                        target="_blank"
                        rel="noreferrer"
                    >Linkki varaukseen</a>.
                </StyledBodyText>
                <StyledTypography variant='h4'>
                    Scandic Hotel Börs
                </StyledTypography>
                <StyledBodyText>
                    Käyttämällä koodia <strong>FIARB</strong> saatte pienen alennuksen  Scandic Hotel Börsistä hääpäivänä. <a
                        href='https://www.scandichotels.fi/?bookingcode=FIARB'
                        target="_blank"
                        rel="noreferrer"
                    >Linkki varaukseen</a>
                </StyledBodyText>
                <StyledTypography variant='h3'>
                Muistaminen
                </StyledTypography>
                <StyledBodyText>
                Mikäli haluatte muistaa meitä hääpäivänämme, toiveenamme on, että kartuttaisitte yhteistä häämatkatiliämme.
                </StyledBodyText>
                <br/>
                <StyledBodyText>
                    Tilinumero:
                </StyledBodyText>
                <div style={{alignContent: 'left'}}>
                    <span style={{
                        backgroundColor: 'rgb(234 243 251)',
                        margin: '8px',
                        padding: '8px',
                        borderRadius: '5px 5px 5px',
                        border: '1px solid rgb(84 103 173)',
                        paddingLeft: '10px',
                        paddingRight: '0px',
                    }}>
                        <code>{contextGlobalConfigs?.globalConfigs?.gifts_IBAN} </code>
                        <CopyToClipboardButton data={contextGlobalConfigs?.globalConfigs?.gifts_IBAN || '-'} />
                    </span>
                    <StyledBodyText>
                        Saaja:
                    </StyledBodyText>
                    <StyledBodyText>
                        {contextGlobalConfigs?.globalConfigs?.gifts_ReceiverName1}
                    </StyledBodyText>
                    <br/>
                    <br/>
                </div>
                <StyledTypography variant='h3'>
                    Yhteystiedot
                </StyledTypography>
                <StyledBodyText>
                    Olettehan rohkeasti yhteydessä meihin, jos teille tulee häihimme liittyen kysyttävää.
                    <br></br>
                    <br></br>
                    {contact2_Phone}
                    <br></br>
                    {contact1_Phone}
{/*
                    <StyledTypography variant='h4'>
                    Häätiimi
                    </StyledTypography>

                    Kaaso 1/5: {contact3_Phone}
                    <br></br>
                    Bestman 1/5: {contact4_Phone} */}

                </StyledBodyText>
                <br></br>
                <br></br>
                <StyledTypography variant='h3' style={{ paddingBottom: '0px'}}>
                    Hääterveisin,
                </StyledTypography>
                <StyledTypography variant='h4' style={{ paddingTop: '0px',paddingBottom: '10px'}}>
                    Anu ja Eetu
                </StyledTypography>
            </Container>
            <img
                style={{
                    width: '100%',
                    maxWidth: '1920px',
                    verticalAlign:'middle',
                    justifyContent: 'center'
                }}

                src='/img/Rings.jpg'
                alt='rings'
            >
            </img>
        </div>
    );
}

export default InformationPage;